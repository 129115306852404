import PageHero from '@/components/PageHero'

export default function PrivacyPolicy() {
  const sections = [
    {
      title: 'Information We Accumulate',
      content: (
        <>
          <p>
            We may gather and process your enlisted information/data whenever
            you visit our website: Information related to our websites used by
            you, such as details of your visits, our pages you viewed, and the
            resources you used/accessed. Information of IP address, location
            data, traffic data, communication data, mobile software and hardware
            information, web browser information, services used and how many
            times our pages and areas you visit, the total time spent on our
            specific pages, etc. You provide details voluntarily and willingly
            when you disseminate with us by any standards. This information
            contains your name, email, address, and contact number.
          </p>
        </>
      )
    },
    {
      title: 'Use of Your Information',
      content: (
        <>
          <p>
            To provide you with the information you have asked us regarding our
            services. To inform you of any modifications to our website and
            services. To enhance our website and client service and to customize
            your experience while visiting our website. To process your
            transactions. Assist in promotion, contest, survey, event, or other
            similar activities. If you agree to obtain further information from
            us, we will use our gathered data to inform you of other services or
            new launches that might engage you. With your consent, we may also
            permit select third parties to send you information regarding
            services suitable to you. If you want to withdraw your permission
            for the same at any moment, you may do so per your requirements.
          </p>
        </>
      )
    },
    {
      title: 'Safety of Your Confidential Information',
      content: (
        <>
          <p>
            Any data sent via the Internet is not entirely protected, and such
            data can sometimes be precluded. We cannot guarantee the data's
            absolute security that you send us electronically, and we accept the
            belief that you are transmitting your information completely at your
            own risk. But, once we get your information, we will do our best to
            guarantee that all data you provide is safely saved with us.
          </p>
        </>
      )
    },
    {
      title: 'Use of Cookies in Collecting Data',
      content: (
        <>
          <p>
            "Cookies" are small information sent to your browser by a website
            you visit. This information will not recognize our users privately.
            Also, this statistical data does not determine any personal details
            of our users in any manner. We may use cookies to gather data and
            information to help us enhance our website.
          </p>
          <p>
            You can set it to have your computer or mobile on alert whenever a
            cookie is sent. But, when you turn off our cookies, some of our
            website features or services may not perform correctly. Our
            advertisers may also operate cookies, over which we do not hold any
            command. We also use analytical tools from third-party to get
            quality insights into our website. These tools may also use cookies
            to collect statistical statements on the website's on-site activity.
          </p>
          <p>
            Our certain website aspects may permit users to deliver content and
            comments publicly. You have to accept that we may reproduce,
            disseminate, and post those content and comments. We cannot
            scrutinize or guarantee the privacy or security of this publicly
            posted information. But, we won't use these tools to track any
            privately identifiable details of our users.
          </p>
        </>
      )
    },
    {
      title: 'Disclosure of Information',
      content: (
        <>
          <p>
            Washington Advert does not share, market, trade, or lease any user's
            private data to any third party for industrial goals except
            following this privacy policy and under certain circumstances such
            as: Where we are lawfully mandated by law to disclose specific
            personal data or Where we ought to reveal that to lower the risk of
            scheming or dishonest activities or Under the circumstances
            concerning potential dangers to the physical safeness of any person
            or Where the user breaks the terms and conditions or Where we market
            our company or any or all our enterprise to another buyer/party. We
            may share a few of your information and generic accumulated
            information not related to any private identification with our joint
            ventures, subsidiaries, or other businesses under mutual command,
            certified and reliable associations, partners, and advertisers for
            marketing initiatives and following as per the terms and conditions
            of this privacy policy. Further, we may share your data with third
            parties that support us and provide our services on our behalf. For
            instance, we may use third parties for transaction approval and
            procedure, website administration, or running the software. Your
            personal details may be carried out and processed by our third-party
            vendors at that moment. Even after such situations, we do not allow
            or entitle them to use or reveal users' personal information for
            marketing or other objectives.
          </p>
        </>
      )
    },
    {
      title: 'Request for Deletion',
      content: (
        <>
          <p>
            You may not be able to use specific portions of our website or
            services if you request us to delete your personal information or
            data at any moment. Since we delete such information, it will be
            automatically deleted from our active database but may stay in our
            archives. We may possess this information for fabrication security
            and various legal requirements.
          </p>
        </>
      )
    },
    {
      title: 'Not For Use by Individuals Below 18 Years of Age',
      content: (
        <>
          <p>
            Our website and services are not planned for individuals under
            eighteen. We deliberately don't collect identifiable information
            from anyone below 18. Before requesting our services, the parents or
            legal guardians must attend to such individuals. We do not allow a
            minor by themselves to make a contract with us as per law. If any
            parent or lawful guardian finds that their children below 18 have
            transferred their personal information with us, they must reach out
            to us instantly. We guarantee that we will take the mandated steps
            to dismiss that information from our databases and servers as
            quickly as possible.
          </p>
        </>
      )
    },
    {
      title: 'Third-Party Content or Website Links on Our Website',
      content: (
        <>
          <p>
            Our website may carry third parties websites or content links, but
            it does mean that we endorse that site's policy towards our
            visitors' privacy. Any website's credentials or links are at the
            user's risk. Our privacy policy doesn't involve any third-party
            content associated with our website, and users are recommended to
            exercise their control while accessing any such website or content
            link.
          </p>
        </>
      )
    },
    {
      title: 'Your Option to Unsubscribe',
      content: (
        <>
          <p>
            You may receive emails or communications that promote our services
            periodically from us. It may exhibit a preference to discontinue
            obtaining further emails or communications from us by following the
            unsubscribe instructions provided in the email you get or by
            directly reaching out to us.
          </p>
        </>
      )
    },
    {
      title: 'Applicability',
      content: (
        <>
          <p>
            This privacy policy only involves information and data collected
            online through our website, not information accumulated offline or
            in any other way.
          </p>
        </>
      )
    },
    {
      title: 'Modifications To This Privacy Policy',
      content: (
        <>
          <p>
            We may periodically modify, revise, amend, correct or update our
            privacy policy to yield with growing Internet usage practices and
            requirements. We firmly suggest you go through our privacy policy
            whenever you visit our website to confirm that you are aware of our
            existing policy. Your continued visit to our site indicates you have
            reviewed and accepted our updated privacy policy.
          </p>
        </>
      )
    },
    {
      title: 'Feedback',
      content: (
        <>
          <p>
            Washington Advert Private Limited is devoted to providing quality,
            excellence, efficiency, promptness, and efficacy in users'
            assistance by maintaining and preserving users' privacy. We do our
            best to avoid any objections, complaints, or disputes. If any
            dispute or complaint occurs regarding clarification, suggestions, or
            information about this privacy policy, feel free to contact us.
          </p>
        </>
      )
    }
  ]

  return (
    <section>
      <div className="text-center text-whiteout bg-whiteout">
        <PageHero heading="Privacy Policy" />
      </div>
      <div className="px-10 md:px-16 py-10 sm:py-12 md:py-16 font-medium">
        <p>
          At Washington Advert Private Limited, while appreciating your privacy,
          we are committed to preserving, safeguarding, and delivering complete
          security to the privacy of our visitors. This privacy policy briefly
          describes how Washington Advert will process and use your data. Our
          service considers your approval to collect, use, or reveal your
          information following this Privacy Policy. The privacy policy needs to
          go through with our terms & conditions. We update our Privacy Policy
          regularly, so check this page accordingly. We use cookies and ask your
          permission to allow those cookies when you come to our website. To
          avoid destroying or manipulating your data and credentials by an
          unauthorized person, we use the latest technical and organizational
          security standards to protect our website.
        </p>
        {sections.map((section, index) => (
          <div className="mt-6 md:mt-8" key={index}>
            <p className="text-2xl md:text-3xl font-medium md:font-semibold py-2">
              {section.title}
            </p>
            {section.content}
          </div>
        ))}
      </div>
    </section>
  )
}
