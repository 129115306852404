export const CONSTANTS = {
  PHONE_NUMBER: {
    min: 8,
    max: 15
  },
  WEBSITE_URL: {
    min: 5,
    max: 255
  },
  NAME: {
    min: 3,
    max: 50
  },
  MESSAGE: {
    min: 10,
    max: 500
  },
  PHONE: '',
  EMAIL: 'info@washingtonadvert.com',
  ADDRESS: {
    TEXT: '4315 50th Street NW Suite 100, Washington',
    URL: 'https://g.co/kgs/tQtZ2QW'
  }
}
